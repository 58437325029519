<template>
    <div class="header">
        <div class="inner">
            <div class="left-tvf">
                <a href="https://www.mingcute.com/">
                <div class="logo">
                    <i></i>
                    <span>MGC Icon System</span>
                    <div class="mark">PRO</div>
                </div>
                </a>
            </div>
            <div class="right-tvf">
                <div class="opt">
                    <a href="javascript:;" class="version" @click="handleVersion">
                        <i></i>
                        <s>V 1.35</s>
                    </a>
                    <div class="opt-menu" id="lottieTop-menu_line" @click="clickMenu('menu_line')"><i :class="{ 'close': isShowMenu }"></i></div>
                    <a href="javascript:;" class="link" @click="gotoPricing"><span>Pricing</span></a>
                    <a href="javascript:;" class="link" @click="gotoFAQ"><span>FAQs</span></a>
                    <a href="https://www.mingcute.com/animation" target="_blank" class="link"><span>Animation</span></a>
                    <a href="https://mgcweather.framer.website/" target="_blank" class="link"><span>Weather</span></a>
                    <a href="https://mgcall.framer.website/" target="_blank" class="link"><span>All-Access</span></a>
                    <a href="https://store.mingcute.com" target="_blank" class="link"><span>Store</span></a>
                    <!-- data-animation-path="../assets/lottieOther/arrow_right_line.json"  -->
                    <a href="https://store.mingcute.com/buy/3099f1cf-b0dc-4494-b739-8a1517c5035d" target="_blank" class="button box-arrow_right_line" @mouseenter.native="playAnimation($event,'arrow_right_line')"><span>Buy now</span><i id="lottieOther-arrow_right_line" class="lottieOther-arrow_right_line arrow"></i></a>
                </div>
            </div>
        </div>
        <!-- 移动端 -->
        <div class="m-menu-opt" v-show="isShowMenu" style="display: block;">
            <a href="javascript:;" @click="gotoPricing"><span>Pricing</span></a>
            <a href="javascript:;" @click="gotoFAQ">FAQs</a>
            <a href="https://www.mingcute.com/animation" target="_blank"><span>Animation</span></a>
            <a href="https://mgcweather.framer.website/" target="_blank"><span>Weather</span></a>
            <a href="https://mgcall.framer.website/" target="_blank" class="link"><span>All-Access</span></a>
            <a href="https://store.mingcute.com/" target="_blank"><span>Store</span></a>
        </div>
        <div class="m-gray-shadow" v-show="isShowMenu" style="display: block;" @click="showmMenu"></div>

        <!-- versionDg -->
        <Dialog
            ref="versionDg"
            show_top=""
            title=""
            width="800px"
        >
            <template v-slot:body>
                <div class="version-dialog">
                    <div class="version-scroll">
                        <div class="title">What's New?</div>
                        <div class="version-cell">
                            <div class="time">Dec 18, 2024</div>
                            <div class="version-title">V1.35 : Add new Zodiac category, dark and light mode, original brand logos</div>
                            <div class="cont">
                                <p><span class="txt-bold">Total : </span>14,118 icons, 26 categories</p>
                                <p><span class="txt-bold">Color variables : </span>switch between light and dark mode, as well as change colors, using Figma variables.</p>
                                <p><span class="txt-bold">Added 420 icons : </span>laurel_wreath, flash_circle, bookmark_add, bookmark_edit, bookmark_remove, empty_box, paste, flower_5, watering_can, Aquarius, Aries, Cancer, Capricorn, Gemini, Leo, Libra, Pisces, Sagittarius, Scorpio, Taurus, Virgo, alipay, apple_intelligence, apple_intelligence_frame, bilibili, copilot, follow, netease_music, siri_frame, airbnb, airdrop, android_2, android, apple, appstore, arc_browser, behance, bluesky_social, carplay, chrome, codepen, dingtalk, discord, dribbble, drive, dropbox, edge, facebook, figma, firefox, git_lab, github_2, github, google, google_play, grok, gumroad, instagram, kakao_talk, layers, lemon_squeezy, line_app, linear, linkedin, linux, mastercard, mastodon, medium, messenger, meta, mingcute, moment, nfc, nintendo_switch, notion, openai, paypal, pinterest, qq, react, reddit, safari, siri, snapchat, social_x, spotify, stripe, telegram, threads, tiktok, trello_board, twitter, viber_messenger, visa, vkontakte, vscode, vue, wechat, wechat_miniprogram, wechat_pay, weibo, whatsapp, windows, xbox, youtube</p>
                            </div>
                        </div>
                        <s class="split"></s>
                        <div class="version-cell">
                            <div class="time">Aug 8, 2024</div>
                            <div class="version-title">V1.3 : Added 531 new icons, Figma keywords</div>
                            <div class="cont">
                                <p><span class="txt-bold">Total : </span>13,698 icons, 25 categories</p>
                                <p><span class="txt-bold">Fast search : </span>Added search keywords to all icon components in Figma</p>
                                <p><span class="txt-bold">Added 531 icons : </span>add_square, ai, bowl_2, briefcase_2, camera_2_ai, cash_2, cellphone_2_ai, close_square, content_ai, corner_down_left, corner_down_right, corner_up_left, corner_up_right, cylinder_2, fault, globe_2, hemisphere_2, hemisphere, hexagons_2, hexagons, laugh, layers, lipstick, mail_ai, mic_ai, minus_square, music_2_ai, paint_brush_ai, paper_2, parfum, pencil_2_ai, pic_ai, quill_pen_ai, refresh_4_ai, rewind_backward_5, rewind_backward_10, rewind_backward_15, rewind_backward_30, rewind_backward_square_5, rewind_backward_square_10, rewind_backward_square_15, rewind_backward_square_30, rewind_forward_5, rewind_forward_10, rewind_forward_15, rewind_forward_30, rewind_forward_square_5, rewind_forward_square_10, rewind_forward_square_15, rewind_forward_square_30, search_ai, siri, sparkles_3, textbox_ai, time_duration, timeline, translate_2_ai, vscode, webhook</p>
                            </div>
                        </div>
                        <s class="split"></s>
                        <div class="version-cell">
                            <div class="time">Jun 10, 2024</div>
                            <div class="version-title">V1.2 : New style, cute light, cute regular, cute filled</div>
                            <div class="cont">
                                <p><span class="txt-bold">Total : </span> 13,167 icons, 25 categories</p>
                                <p><span class="txt-bold">Added 3 new styles : </span> 4389 icons</p>
                            </div>
                        </div>
                        <s class="split"></s>
                        <div class="version-cell">
                            <div class="time">Mar 18, 2024</div>
                            <div class="version-title">V1.0 : added 348 new icons, fixed 28 icons</div>
                            <div class="cont">
                                <p><span class="txt-bold">Total : </span>8778 icons, 25 categories</p>
                                <p><span class="txt-bold">Added 348 icons : </span>arc_browser, behance, beside_table_2, beside_table, bill_2, blueskey_social, bottle, bottle_glass, bulb_2, candles, cellphone_2, cellphone_2_horizontal, chef_hat, crystal_ball, currency_nigeria_2, currency_nigeria, cylinder, dinner, disabled_2, disabled, dish_cover, donut, electric_cooker, fax, fingerprint_2, folder_zip, fried_egg, grok, heart_hand, home_7, house_2, line, live, main_map, news_2, nfc, notification_newdot, presentation_3, projector, projector_screen, random, rectangle, rectangle_vertical, remote_control, screwdriver, shower, shower_gel, sound_line, soup_pot_2, soup_pot, spatula, star_topper, text_area, text_direction_left, text_direction_right, video_camera_2, video_camera, wheelchair</p>
                                <p><span class="txt-bold">Fixed 28 icons : </span>tv_2, battery_automotive, air_condition_open, rss_2, battery_1, vison_pro, certificate_2, drum</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Dialog>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
// import lottie from 'lottie-web'

// import menuLine from '../../assets/menu_line.json';
export default defineComponent({
    name: 'Header',
    data() {
        return {
            isShowMenu: false,
            // lottieTop: [
            //     {
            //         "name": "menu_line",
            //         "loop": false,
            //         "autoplay": false,
            //     },
            // ],
            // directionMenu: 1,
            // valueProgress: 0,
            // intervalId: '',
            // intervalId2: '',
            // lottieTopAnimations: [],
            // animationInstanceTop: null,
        }
    },
    methods: {
        showmMenu() {
            this.isShowMenu = !this.isShowMenu;
        },
        // loadTopAnimation() {
        //     this.lottieTop.forEach((item, index) => {
        //         const container = document.getElementById(`lottieTop-${item.name}`);

        //         const animationConfig = {
        //             container: container,
        //             renderer: 'svg',
        //             loop: item.loop,
        //             autoplay: item.autoplay,
        //             path: `../../assets/${item.name}.json`,
        //         };


        //         this.animationInstanceTop = lottie.loadAnimation(animationConfig);

        //         this.lottieTopAnimations.push(this.animationInstanceTop);
        //     });
        // },
        // playAnimation(name) {
        //     let index = -1;
        //     for(let i=0; i < this.lottieTop.length; i++) {
        //         if(this.lottieTop[i].name == name) {
        //             index = i;
        //             break;
        //         }
        //     }
        //     this.lottieTopAnimations[index].playSegments([0, this.lottieTopAnimations[index].animationData.op], true);
        // },
        // pauseAnimation(name) {
        //     let index = -1;
        //     for(let i=0; i < this.lottieTop.length; i++) {
        //         if(this.lottieTop[i].name == name) {
        //             index = i;
        //             break;
        //         }
        //     }
        //     this.lottieTopAnimations[index].stop();
        // },
        // destroyAnimationTop() {
        //     if(this.animationInstanceTop) {
        //         console.log('destroyingTop');
        //         this.lottieTopAnimations.forEach(animation => {
        //             animation.destroy();
        //         });
        //         this.lottieTopAnimations = [];
        //         this.animationInstanceTop = null;
        //     }
        // },
        clickMenu(name) {
            this.isShowMenu = !this.isShowMenu;
            // let index = -1;
            // for(let i=0; i < this.lottieTop.length; i++) {
            //     if(this.lottieTop[i].name == name) {
            //         index = i;
            //         break;
            //     }
            // }


            // if(!this.isShowMenu && this.intervalId) {
            //     clearInterval(this.intervalId);
            // }

            // if(this.isShowMenu && this.intervalId2) {
            //     clearInterval(this.intervalId2);
            // }
            
            // const nowLottie = this.lottieTopAnimations[index];
            
            // if(this.directionMenu == 1 && this.isShowMenu) {
            //     this.directionMenu = -1;

            //     this.intervalId = setInterval(() => {
            //         if (this.valueProgress >= 20) {
                        
            //             clearInterval(this.intervalId);
            //             this.intervalId = '';
            //             return;
            //         }
            //         this.valueProgress = this.valueProgress + 1;
            //         nowLottie.goToAndStop(this.valueProgress, true);
            //         nowLottie.pause();
            //     }, 1);
                
            // } else if(this.directionMenu == -1 && !this.isShowMenu) {
            //     this.directionMenu = 1;
            //     this.intervalId2 = setInterval(() => {
            //         if (this.valueProgress <= 0) {
            //             clearInterval(this.intervalId2);
            //             this.intervalId2 = '';
            //             return;
            //         }
            //         this.valueProgress = this.valueProgress - 1;
            //         nowLottie.goToAndStop(this.valueProgress, true);
            //         nowLottie.pause();
            //     }, 1);
            // }
        },
        gotoFAQ() {
            if(this.isShowMenu) {
                this.clickMenu('menu_line');
            }
            this.$emit('gotoFAQ');
        },
        gotoPricing() {
            if(this.isShowMenu) {
                this.clickMenu('menu_line');
            }
            this.$emit('gotoPricing');
        },
        handleVersion() {
            this.$refs.versionDg.showBox();
        },
        playAnimation(event,name) {
            this.$emit('playAnimation',event,name);
        }
    },
    beforeDestroy() {
        // this.destroyAnimationTop();
    },
    mounted() {
        // this.loadTopAnimation();
    }
});

</script>